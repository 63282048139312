import React, { ReactElement, ReactNode } from 'react'
import { Popover, IconButton, SvgIcon, Typography } from '@material-ui/core'
import Info from '@tdcerhverv/parrotfish/dist/icons/Icons/Info.svg'
import { makeStyles } from '@material-ui/core/styles'

export interface IPopoverInfo {
  text: string
}

const useStyles = makeStyles(theme => ({
  icon: {
    width: 14,
    height: 14,
  },
  popOverInfo: {
    '& .MuiPaper-root': {
      padding: 16,
    },
  },
}))

const PopOverInfo: (props: IPopoverInfo) => ReactElement = ({ text }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const classes = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = 'popover-info'
  return (
    <>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        style={{ padding: '0 0 0 8px' }}
      >
        <SvgIcon
          component={Info}
          viewBox={'0 0 48 48'}
          className={classes.icon}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disablePortal={true}
        className={classes.popOverInfo}
      >
        {text && <Typography>{text}</Typography>}
      </Popover>
    </>
  )
}

export default PopOverInfo
